import React from 'react';
import AppContext from './AppContext';

// Importing necessary stylesheets
import '../Styles/Colors.css';
import '../Styles/NeonEffects.css';
import '../Styles/ImageDrop.css';

function ImageDrop() {

  // Declare state for the image source
  const { imageSrc, setImageSrc } = React.useContext(AppContext);
  
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const handleClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleFileChange = (e) => {
    if (e.target.files.length) {
      handleFiles(e.target.files);
    }
  };

  const handleFiles = (files) => {
    const file = files[0];
    const validFormats = ['image/png', 'image/gif'];
    
    if (!validFormats.includes(file.type)) {
      alert('Invalid file format. Please upload a PNG or GIF.');
      return;
    }
    
    if (file.size > 10000000) { // 10MB
      alert('File size exceeds the limit.');
      return;
    }

    const image = new Image();
    image.onload = function() {
      if (this.width < 600 || this.width > 3000 || this.height < 600 || this.height > 3000 || this.width !== this.height) {
        alert('Image dimensions should be square and between 600x600 to 3000x3000 pixels.');
        return;
      }
      
      // Directly set the image source state as the file object
      setImageSrc(file);
    };
    
    image.onerror = function() {
      alert('Error loading the image. Please try another file.');
    };
    
    image.src = URL.createObjectURL(file);
  };

  return (
    <div className="image-drop-area" onDrop={handleDrop} onDragOver={handleDragOver}>
        {imageSrc ? (
            <img src={URL.createObjectURL(imageSrc)} alt="Dropped content" className="dropped-image" />
        ) : (
            <>
                <p>Drag & Drop your image here <span onClick={handleClick}>or browse</span></p>
                <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange} />
            </>
        )}
    </div>
  );

}

export default ImageDrop;
