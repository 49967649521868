import React, { createContext, useContext, useEffect, useState } from 'react';
import Web3 from 'web3';

// Create a context
export const Web3Context = createContext(null);

export function Web3Provider({ children }) {
  const [web3, setWeb3] = useState(null);

  useEffect(() => {
    // Initialize web3 instance on window load
    window.addEventListener('load', async () => {
      if (window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);
      } else {
        console.error('MetaMask not found!');
      }
    });
  }, []);

  return (
    <Web3Context.Provider value={web3}>
      {children}
    </Web3Context.Provider>
  );
}

export const useWeb3 = () => useContext(Web3Context);
