import React from 'react';
import '../Styles/URIModal.css';

function URIModal({ isOpen, uri, onClose, onCopy }) {
    if (!isOpen) return null;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(uri).then(() => {
            alert('URI copied to clipboard!');
            onClose();
    
            // Scroll to AddressUpload section
            const addressUploadElement = document.querySelector('.address-upload-section');
            if (addressUploadElement) {
                addressUploadElement.scrollIntoView();
            }
        });
    };

    return (
        <div className="modal-overlay">
            <div className="modal">
                <div className="modal-header">
                    <h2>NFT URI for your records</h2>
                </div>
                <div className="modal-content">
                    {uri}
                </div>
                <div className="modal-footer">
                    <button onClick={copyToClipboard}>Copy URI</button>
                </div>
            </div>
        </div>
    );
    
}

export default URIModal;
