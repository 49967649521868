import React, { useState, useRef } from 'react';  // Import useRef
import '../Styles/AddressUpload.css';

function AddressUpload({ onValidFile }) {
    const [fileError, setFileError] = useState(null);
    
    // This reference is used to target the DOM element for scrolling
    const addressUploadRef = useRef(null);

    const isValidEthereumAddress = (address) => {
        const pattern = /^0x[a-fA-F0-9]{40}$/;
        return pattern.test(address);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (evt) => {
            // Splitting and validating file content
            const addresses = evt.target.result.replace(/\r/g, '').split('\n');
            const isValid = addresses.every(address => isValidEthereumAddress(address.trim()));

            if (isValid) {
                onValidFile(addresses);
            } else {
                setFileError('Invalid file content. Please ensure the file only contains valid Ethereum addresses.');
            }
        };
        reader.onerror = () => {
            setFileError('Error reading the file.');
        };
        reader.readAsText(file);
    };

    return (
        <div className="address-upload-section" ref={addressUploadRef}>
            <h2>Upload Ethereum Addresses</h2>
            <input type="file" onChange={handleFileChange} />
            {fileError && <p className="error">{fileError}</p>}
        </div>
    );
}

export default AddressUpload;
