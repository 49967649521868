import React from 'react';
import logo from '../Assets/logo.png';

// Importing necessary stylesheets
import '../Styles/Colors.css';
import '../Styles/NeonEffects.css';

const Header = () => {
  return (
    <div className="header-container" style={{ padding: '10px' }}>
      <img src={logo} alt="WHALE" className="neon-glow" style={{ width: '200px', height: 'auto', marginBottom: '10px', marginTop: '100px' }} />
      <h1 className="color-pink">WHALE MINT</h1>
      <h2 className="color-green">by Zorro</h2>
    </div>
  );
}

export default Header;
