import React, { useState } from 'react';
import { checkIfAddressApproved } from '../Utils/api';

// Importing necessary stylesheets
import '../Styles/Colors.css';
import '../Styles/NeonEffects.css';
import '../Styles/Buttons.css';

function Web3Connect({ onVerified }) {
  const [isConnected, setIsConnected] = useState(false);
  const [address, setAddress] = useState('');

  const connectMetaMask = async () => {
    // Check if MetaMask is installed
    if (!window.ethereum) {
      console.log('MetaMask is not installed.');
      alert('MetaMask is not installed. Please install it and try again.');
      return;
    }

    // Request account access
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      if (accounts.length) {
        const userAddress = accounts[0].toLowerCase();  // Normalize the address here
        setIsConnected(true);
        setAddress(userAddress);
        
        // Check if the address is approved
        const isApproved = await checkIfAddressApproved(userAddress);
        if (isApproved) {
          alert('Your address is approved!');
          onVerified(userAddress);  // Notify parent (App.js) once the address is verified
        } else {
          alert('Your address is not approved. Please use an approved address.');
        }

      }
    } catch (error) {
      console.error('Failed to connect to MetaMask or check address:', error);
      alert('An error occurred. Please check the console for more details.');
    }
  };

  return (
    <div>
      <button onClick={connectMetaMask} className="button neon" style={{ marginBottom: '20px' }}>
          {isConnected ? 'CONNECTED' : 'Connect MetaMask'}
      </button>
      {isConnected && (
        <div>
          <div>Your Address: {address}</div>
          <div>Polygon Contract Address: 0x6e444304b26D8C2658C53B2d7e81A65c98D052cB</div>
        </div>
      )}
    </div>
  );

}

export default Web3Connect;
