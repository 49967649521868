import React, { useState } from 'react';
import '../Styles/Review.css';

function Review({ isOpen, imageSrc, name, description, properties, addresses, onConfirm, onEdit, onReset }) {
    const [isMinting, setIsMinting] = useState(false);

    const handleMinting = async () => {
        setIsMinting(true);
        try {
            await onConfirm();
        } catch (error) {
            console.error('Error during minting:', error);
        }
        setIsMinting(false);
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal">
                <div className="modal-header">
                    <h2>Review NFT Details</h2>
                </div>
                <div className="modal-content">
                    <h3>Name: {name}</h3>
                    <p>Description: {description}</p>
                    
                    <div>
                        <strong>Properties:</strong>
                        <ul>
                            {Object.entries(properties).map(([key, value]) => (
                                <li key={key}>{key}: {value}</li>
                            ))}
                        </ul>
                    </div>
                    
                    <p><strong>Number of Addresses:</strong> {addresses.length}</p>
                </div>
                <div className="modal-footer">
                    {isMinting ? (
                        <div className="spinner"></div>
                    ) : (
                        <>
                            <button onClick={handleMinting}>CONFIRM AND MINT</button>
                            <button onClick={onReset}>RESET</button>
                            <button onClick={onEdit}>EDIT</button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Review;
