// EventTitle.js
import React from 'react';
import AppContext from './AppContext';

// Importing necessary stylesheets
import '../Styles/EventTitle.css';

function EventTitle() {
    const { name, setName, description, setDescription } = React.useContext(AppContext);

    return (
        <div className="event-title-container">
            <div className="input-group">
                <label htmlFor="eventName">Name:</label>
                <input
                    type="text"
                    id="eventName"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    placeholder="Enter event name"
                />
            </div>

            <div className="input-group">
                <label htmlFor="eventDescription">Description:</label>
                <textarea
                    id="eventDescription"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    placeholder="Enter event description"
                    rows="4" // adjust as needed
                />
            </div>
        </div>
    );
}

export default EventTitle;