import React from 'react';
import ImageDrop from './ImageDrop';
import EventTitle from './EventTitle';
import RadioButtons from './RadioButtons';
import Properties from './Properties';

// Importing necessary stylesheets
import '../Styles/Colors.css';
import '../Styles/NeonEffects.css';

function VerifiedComponents({ handleStore }) { 
    return (
        <>
            <div className="details-container">
                <EventTitle />
                <RadioButtons />
                <Properties />
            </div>
            <ImageDrop />
            {/* ... other components ... */}
        </>
    );
}

export default VerifiedComponents;