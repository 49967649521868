import React from 'react';
import '../Styles/SuccessMessage.css';

function SuccessMessage({ isOpen, onClose }) {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal">
                <div className="modal-header">
                    <h2>Success!</h2>
                </div>
                <div className="modal-content">
                    Your NFT tokens have been successfully minted.
                </div>
                <div className="modal-footer">
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
}

export default SuccessMessage;
