export const checkIfAddressApproved = async (address) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await fetch(`${apiUrl}/api/check-address`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ address }),
    });
    const data = await response.json();
    return data.approved;
};

export const storeOnIPFS = async (imageSrc, name, description, properties) => {
    try {
        const formData = new FormData();
        formData.append('image', imageSrc);
        formData.append('name', name);
        formData.append('description', description);
        formData.append('properties', JSON.stringify(properties));

        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/api/store-token`, {
            method: 'POST',
            body: formData
        });

        const result = await response.json();
        
        if (response.ok) {
            return result.uri;
        } else {
            throw new Error(result.error);
        }
    } catch (error) {
        console.error('Error storing on IPFS:', error);
        throw error;
    }
};
