import React, { useContext } from 'react';
import AppContext from './AppContext';
import '../Styles/RadioButtons.css';

function RadioButtons() {
    const { imageCategory, setImageCategory } = useContext(AppContext);
        
    return (
        <div className="radio-buttons-container">
            <label className="radio-type1">
                <input 
                    type="radio" 
                    value="type1"
                    checked={imageCategory === "type1"}
                    onChange={() => setImageCategory("type1")}
                />
                Momento
            </label>
            
            <label className="radio-type2">
                <input 
                    type="radio" 
                    value="type2"
                    checked={imageCategory === "type2"}
                    onChange={() => setImageCategory("type2")}
                />
                Honor
            </label>

            <label className="radio-type3">
                <input 
                    type="radio" 
                    value="type3"
                    checked={imageCategory === "type3"}
                    onChange={() => setImageCategory("type3")}
                />
                Badge
            </label>

            {/* ... Add other types as needed ... */}
        </div>
    );
}

export default RadioButtons;
