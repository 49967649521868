import React, { useState, useRef, useEffect } from 'react';
import { Web3Provider } from './Providers/Web3Provider';
import AppContext from './Components/AppContext';

// Components
import Header from './Components/Header';
import Web3Connect from './Components/Web3Connect';
import VerifiedComponents from './Components/VerifiedComponents';
import { storeOnIPFS } from './Utils/api';
import URIModal from './Components/URIModal';
import AddressUpload from './Components/AddressUpload';
import Review from './Components/Review';
import SuccessMessage from './Components/SuccessMessage';

// Styles
import './Styles/App.css';
import './Styles/Body.css';

function App() {
    const [isUserVerified, setIsUserVerified] = useState(false);
    const verifiedSectionRef = useRef(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [imageType, setImageType] = useState(null);
    const [imageCategory, setImageCategory] = useState(null);
    const [storedUri, setStoredUri] = useState(null);
    const [propertiesData, setPropertiesData] = useState({});
    const [showURIModal, setShowURIModal] = useState(false);
    const [showAddressUpload, setShowAddressUpload] = useState(false);
    const [showReviewModal, setShowReviewModal] = useState(false);
    const [uploadedAddresses, setUploadedAddresses] = useState([]);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [isStoring, setIsStoring] = useState(false);
    const addressUploadRef = useRef(null);
    
    const handleUserVerification = (address) => {
        setIsUserVerified(true);
    };

    const handleCopyURI = () => {
        const textArea = document.createElement('textarea');
        textArea.value = storedUri; // Use the storedUri from your state
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('Copy');
        textArea.remove();
        alert('URI copied to clipboard!');
    };

    const handleDataStore = async () => {
        console.log("Store button clicked");
        setIsStoring(true);
        
        if (!imageSrc || !name.trim() || !description.trim()) {
            alert('Please ensure you have an image uploaded and the event details filled out.');
            return;
        }
    
        try {
            const uri = await storeOnIPFS(imageSrc, name, description, propertiesData);
            
            setStoredUri(uri);
            setShowURIModal(true);  // Show the modal when the URI is successfully stored
        } catch (error) {
            alert('Error while storing on IPFS: ' + error.message);
        }

        setIsStoring(false);
    };
    
    const handleValidFile = (addresses) => {
        setUploadedAddresses(addresses);
        console.log("Total number of addresses:", addresses.length);
        setShowReviewModal(true);
    };

    const handleConfirmAndMint = async () => {
        try {
            // Making a POST request to the backend endpoint to mint the tokens
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/api/mint-tokens`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    recipients: uploadedAddresses,  // The list of Ethereum addresses uploaded by the user
                    metadataURI: storedUri  // The URI of the metadata stored on NFT.storage
                })
            });
    
            // Parsing the JSON response from the server
            const result = await response.json();
    
            // If the minting was successful (as indicated by the 'success' property in the response)
            if (result.success) {
                // Display the success message (from the server) to the user using an alert
                alert(result.message);
    
                // Reset the state of the App component (clearing the uploaded data and hiding modals)
                resetState();
            } else {
                // If there was an error during minting, display the error message (from the server) to the user
                alert(result.message);
            }
        } catch (error) {
            // If there's any other error (e.g., network error), log it and show a generic error message to the user
            console.error('Error during minting:', error);
            alert('Error during minting. Please try again.');
        }
    };        

    const handleReset = () => {
        // Reset the uploaded addresses and any other temporary data
        setUploadedAddresses([]);
        setShowReviewModal(false);
    };

    const resetState = () => {
        // You can reset other states as well if needed
        setUploadedAddresses([]);
        setShowURIModal(false);
        setShowAddressUpload(false);
        setShowReviewModal(false);
    };

    useEffect(() => {
        if (isUserVerified && verifiedSectionRef.current) {
            // Calculate the position to which we need to scroll
            const yOffset = window.innerHeight / 2 - verifiedSectionRef.current.clientHeight / 2;
            const yPosition = verifiedSectionRef.current.getBoundingClientRect().top + window.pageYOffset - yOffset;
            window.scrollTo({ top: yPosition + yOffset, behavior: 'smooth' });
        }
    }, [isUserVerified]);

    return (
        <Web3Provider>
            <AppContext.Provider value={{ imageSrc, setImageSrc, name, setName, description, setDescription, imageType, setImageType, imageCategory, setImageCategory, propertiesData, setPropertiesData }}>
                <div className="App">
                    <div className="top-section">
                        <div className="header-container">
                            <Header />
                        </div>
                        <div className="Web3Connect-area">
                            <Web3Connect onVerified={handleUserVerification} />
                        </div>
                    </div>
    
                    {isUserVerified && (
                        <div className="verified-section" ref={verifiedSectionRef}>
                            <VerifiedComponents />
                            <div className="store-section">
                                {isStoring ? (
                                    <div className="spinner"></div>
                                ) : (
                                    <button onClick={handleDataStore} className="store-button">STORE on IPFS</button>
                                )}
                            </div>
                        </div>
                    )}
    
                    {showAddressUpload && (
                        <div className="address-upload-section">
                            <AddressUpload onValidFile={handleValidFile} ref={addressUploadRef} />
                        </div>
                    )}
    
                    <URIModal 
                        isOpen={showURIModal} 
                        uri={storedUri} 
                        onClose={() => {
                            setShowURIModal(false);
                            setShowAddressUpload(true); // Show the AddressUpload section after closing the URI modal
                            if (addressUploadRef.current) {
                                setTimeout(() => {
                                    addressUploadRef.current.scrollIntoView({ behavior: 'smooth' });
                                }, 100);
                            }                            
                        }}
                        onCopy={handleCopyURI}
                    />
    
                    <Review
                        isOpen={showReviewModal}
                        imageSrc={imageSrc}
                        name={name}
                        description={description}
                        properties={propertiesData}
                        addresses={uploadedAddresses}
                        onConfirm={handleConfirmAndMint}
                        onEdit={() => setShowReviewModal(false)}
                        onReset={handleReset}
                    />

                    <SuccessMessage 
                        isOpen={showSuccessMessage} 
                        onClose={() => setShowSuccessMessage(false)}
                    />

                </div>
            </AppContext.Provider>
        </Web3Provider>
    );
    
  
}

export default App;
