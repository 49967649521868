import React, { useContext, useEffect } from 'react';  // Added useEffect
import AppContext from './AppContext';
import '../Styles/Properties.css';

function Properties() {
    const { imageCategory, propertiesData, setPropertiesData } = useContext(AppContext);

    const handleInputChange = (property, value) => {
        setPropertiesData(prevProps => ({
            ...prevProps,
            [property]: value
        }));
    };

    useEffect(() => {
        // Set the 'Type' property based on the selected imageCategory
        if (imageCategory === 'type1') {
            setPropertiesData(prevProps => ({
                ...prevProps,
                Type: 'Momento'
            }));
        } else if (imageCategory === 'type2') {
            setPropertiesData(prevProps => ({
                ...prevProps,
                Type: 'Honor'
            }));
        } else if (imageCategory === 'type3') {
            setPropertiesData(prevProps => ({
                ...prevProps,
                Type: 'Badge'
            }));
        }
    }, [imageCategory, setPropertiesData]);

    if (imageCategory === 'type1') {
        return (
            <div className="properties-container">
                <label>Set Momento properties</label>
                <input value="Momento" readOnly />
                <input placeholder="Event" value={propertiesData.Event || ''} onChange={(e) => handleInputChange('Event', e.target.value)} />
                <input placeholder="Episode" value={propertiesData.Episode || ''} onChange={(e) => handleInputChange('Episode', e.target.value)} />
                <input placeholder="Date" value={propertiesData.Date || ''} onChange={(e) => handleInputChange('Date', e.target.value)} />
                <input placeholder="Location" value={propertiesData.Location || ''} onChange={(e) => handleInputChange('Location', e.target.value)} />
                <input placeholder="Hosts" value={propertiesData.Hosts || ''} onChange={(e) => handleInputChange('Hosts', e.target.value)} />
                <input placeholder="Creator" value={propertiesData.Creator || ''} onChange={(e) => handleInputChange('Creator', e.target.value)} />
                <input placeholder="Website" value={propertiesData.Website || ''} onChange={(e) => handleInputChange('Website', e.target.value)} />
            </div>
        );
    }

    if (imageCategory === 'type2') {
        return (
            <div className="properties-container">
                <label>Set Honor properties</label>
                <input value="Honor" readOnly />
                <input placeholder="Category" value={propertiesData.Category || ''} onChange={(e) => handleInputChange('Category', e.target.value)} />
                <input placeholder="Event Theme" value={propertiesData.EventTheme || ''} onChange={(e) => handleInputChange('EventTheme', e.target.value)} />
                <input placeholder="Date" value={propertiesData.Date || ''} onChange={(e) => handleInputChange('Date', e.target.value)} />
                <input placeholder="Architect" value={propertiesData.Architect || ''} onChange={(e) => handleInputChange('Architect', e.target.value)} />
                <input placeholder="Contributions" value={propertiesData.Contributions || ''} onChange={(e) => handleInputChange('Contributions', e.target.value)} />
                <input placeholder="Creator (eg Team WHALE)" value={propertiesData.Creator || ''} onChange={(e) => handleInputChange('Creator', e.target.value)} />
                <input placeholder="Website" value={propertiesData.Website || ''} onChange={(e) => handleInputChange('Website', e.target.value)} />
            </div>
        );
    }

    if (imageCategory === 'type3') {
        return (
            <div className="properties-container">
                <label>Set Badge properties</label>
                <input value="Badge" readOnly />
                <input placeholder="Tier" value={propertiesData.Tier || ''} onChange={(e) => handleInputChange('Tier', e.target.value)} />
                <input placeholder="Date" value={propertiesData.Date || ''} onChange={(e) => handleInputChange('Date', e.target.value)} />
                <input placeholder="Artist" value={propertiesData.Artist || ''} onChange={(e) => handleInputChange('Artist', e.target.value)} />
                <input placeholder="Creator" value={propertiesData.Creator || ''} onChange={(e) => handleInputChange('Creator', e.target.value)} />
                <input placeholder="Website" value={propertiesData.Website || ''} onChange={(e) => handleInputChange('Website', e.target.value)} />
                <input placeholder="Xtwitter" value={propertiesData.Xtwitter || ''} onChange={(e) => handleInputChange('Xtwitter', e.target.value)} />
                <input placeholder="Discord" value={propertiesData.Discord || ''} onChange={(e) => handleInputChange('Discord', e.target.value)} />        
            </div>
        );
    }

    return null; // Return nothing if no type is selected
}

export default Properties;
